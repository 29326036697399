.menu-item-content {
  display: flex;
  flex-direction: column;
}
.heading {
  font-size: 12px;
  margin-bottom: 0;
}

.subheading {
  font-size: 10px;
  color: #808080;
  margin-top: 0;
  margin-bottom: 0;
}
