.heading {
  font-size: 12px;
  font-weight: 500;
}
.subheading {
  font-size: 12px;
  font-weight: 500;
  color: rgb(62, 72, 112);
  margin-left: 120px;
}
.selectedfile {
  height: 200px;
  width: 500px;
  background-color: #eaedf7;
  text-align: center;
  border-radius: 10px;
  border: 1px dashed #808080;
  padding-top: 50px;
}
.dragdrop {
  font-size: 12px;
  color: #808080;
}
.button {
  background-color: rgb(62, 72, 112);
  color: #fff;
  font-weight: 400;
  border: none;
  border-radius: 3px;
  height: 25px;
  margin-top: 7px;
}
.preview {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  border: 1px dashed #808080;
  padding: 10px;
  border-radius: 5px;
  width: 500px;
}
.previewname {
  font-size: 12px;
  font-weight: 500;
  margin-top: 11px;
  color: #808080;
  margin-left: 10px;
}
