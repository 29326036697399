.customButton {
  background-color: rgb(62, 72, 112);
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  height: 40px;
}
