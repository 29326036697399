.container {
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #212529;
  text-align: left;
}

.heading {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}

.subheading {
  font-size: 12px;
  color: #808080;
  line-height: 12px;
}

.hr {
  margin-top: 12px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #000;
}
