body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.App {
  padding: 20px;
}
.hr {
  margin: 0;
}

.input {
  width: calc(100% - 0px);
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
