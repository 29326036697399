body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.email-cell {
  text-align: left;
  padding: 10px;
  vertical-align: top;
}

.email-cell .info {
  margin-bottom: 8px;
}

/* .loading {
  background: url("spinner.gif") no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
} */

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.your-component {
  position: relative;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none !important;
}

.loading-overlay img {
  pointer-events: none !important; /* Allow clicking on the GIF if necessary */
}

.content.blurred {
  filter: blur(2px);
  pointer-events: none;
  user-select: none;
  opacity: 0.6;
}

/* cardcomponent design */

.card-container {
  background-color: #fff; /* Light background */
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(236, 231, 231, 0.532);
  padding: 5px;
  max-width: 286px;
  margin: 8px;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  border: 1px #f4f4f4 solid;
}

.card-container:hover {
  transform: scale(1.05);
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card-icon1 {
  font-size: 40px;
  color: #f50606; /* Dark color for icon */
  margin-right: 15px;
}

.card-details {
  text-align: left;
}

.card-title {
  font-size: 18px;
  color: #31d200; /* Dark text color */
  margin: 0;
}

.card-count {
  font-size: 30px;
  font-weight: bold;
  color: #007bff; /* Light blue color for count */
  margin: 5px 0 0 0;
}
