.media-body {
  margin-bottom: 20px;
}

.message-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.user-avatar {
  margin-right: 10px;
}

.username {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  color: #808080;
}

.message-content {
  background-color: #dcf8c6;
  padding: 8px;
  border-radius: 8px;
}

.attachment-wrapper {
  margin-top: 10px;
}

.image-attachment img {
  max-width: 200px;
}

.file-attachment .attachment-link {
  display: inline-block;
  background-color: #f4f4f4;
  padding: 8px 20px;
  border-radius: 3px;
  color: #333;
  text-decoration: none;
}

.attachment-username {
  margin-top: 5px;
  font-size: 10px;
  color: #808080;
}

/* This affects only the first child element */

/* List styles */
