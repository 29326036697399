/* Global styles */
.nav-item {
  color: inherit; /* Set default color */
}
@media (min-width: 998px) {
  .nav-item {
    height: 45px;
    align-items: center;
    padding-top: 0; /* Hide nav items for small screens */
  }

  .hamburger-menu {
    display: none;
  }
  .menu-item {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }
}

/* Media query for small screens */
@media (max-width: 800px) {
  .nav-item {
    display: none;
    height: 40px;

    /* Hide nav items for small screens */
  }
  .hamburger-menu {
    display: block;
  }
}

/* Media query for medium screens */
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-item {
    width: 50%;
    height: 50px;
    text-align: left;
    padding: 10px 0;
    color: red; /* Color specific to medium screens */
  }
}

/* YourComponent.css */
/* Default styles for the collapsed navbar */
.navbar-collapse {
  display: none;
  /* Other default styles */
}

/* Media query for screens with a maximum width of 768px */
@media screen and (max-width: 768px) {
  /* Styles for the toggler button at smaller screen sizes */

  /* Styles for the collapsed navbar when responsive mode is on */
  .responsive-navbar.responsive-on .navbar-collapse {
    display: block;
  }
}
.dropdown-wrapper {
  position: relative;
  display: inline-block; /* To keep elements on the same line */
}

.user-info {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -100%; /* Start offscreen to the left */
  transition: left 0.3s ease; /* Add a transition for smooth opening */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.show {
  left: 0; /* Move to original position when open */
}
