.note {
  pointer-events: auto;
  box-sizing: border-box;
  background-color: #eaedf7;
  color: #232323;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.24px;
  width: 95%;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
