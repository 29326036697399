/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*Dark Theme*/
/*Dark Theme*/
/*! Pickr 1.8.2 MIT | https://github.com/Simonwep/pickr */
.pickr {
    position: relative;
    overflow: visible;
    transform: translateY(0);
}

.pickr * {
    box-sizing: border-box;
    outline: none;
    border: none;
    -webkit-appearance: none;
}

.pickr .pcr-button {
    position: relative;
    height: 2em;
    width: 2em;
    padding: 0.5em;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    border-radius: 0.15em;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="%2342445A" stroke-width="5px" stroke-linecap="round"><path d="M45,45L5,5"></path><path d="M45,5L5,45"></path></svg>') no-repeat center;
    background-size: 0;
    transition: all 0.3s;
}

.pickr .pcr-button::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 0.5em;
    border-radius: 0.15em;
    z-index: -1;
    z-index: initial;
}

.pickr .pcr-button::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: background 0.3s;
    background: var(--pcr-color);
    border-radius: 0.15em;
}

.pickr .pcr-button.clear {
    background-size: 70%;
}

.pickr .pcr-button.clear::before {
    opacity: 0;
}

.pickr .pcr-button.clear:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
}

.pickr .pcr-button.disabled {
    cursor: not-allowed;
}

.pickr * {
    box-sizing: border-box;
    outline: none;
    border: none;
    -webkit-appearance: none;
}

.pcr-app * {
    box-sizing: border-box;
    outline: none;
    border: none;
    -webkit-appearance: none;
}

.pickr input:focus,
.pickr input.pcr-active {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
}

.pickr button:focus,
.pickr button.pcr-active {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
}

.pcr-app input:focus,
.pcr-app input.pcr-active {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
}

.pcr-app button:focus,
.pcr-app button.pcr-active {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
}

.pickr .pcr-palette,
.pickr .pcr-slider {
    transition: box-shadow 0.3s;
}

.pcr-app .pcr-palette,
.pcr-app .pcr-slider {
    transition: box-shadow 0.3s;
}

.pickr .pcr-palette:focus,
.pickr .pcr-slider:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(0, 0, 0, 0.25);
}

.pcr-app {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    border-radius: 0.1em;
    background: #ffffff;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    box-shadow: 0 0.15em 1.5em 0 rgba(0, 0, 0, 0.1), 0 0 1em 0 rgba(0, 0, 0, 0.03);
    left: 0;
    top: 0;
}

.pcr-app .pcr-palette:focus,
.pcr-app .pcr-slider:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(0, 0, 0, 0.25);
}

.pcr-app.visible {
    transition: opacity 0.3s;
    visibility: visible;
    opacity: 1;
}

.pcr-app .pcr-swatches {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.75em;
}

.pcr-app .pcr-swatches.pcr-last {
    margin: 0;
}

.pcr-app .pcr-swatches>button {
    font-size: 1em;
    position: relative;
    width: calc(1.75em - 5px);
    height: calc(1.75em - 5px);
    border-radius: 0.15em;
    cursor: pointer;
    margin: 2.5px;
    flex-shrink: 0;
    justify-self: center;
    transition: all 0.15s;
    overflow: hidden;
    background: transparent;
    z-index: 1;
}

.pcr-app .pcr-swatches>button::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 6px;
    border-radius: 0.15em;
    z-index: -1;
}

.pcr-app .pcr-swatches>button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--pcr-color);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.15em;
    box-sizing: border-box;
}

.pcr-app .pcr-swatches>button:hover {
    filter: brightness(1.05);
}

.pcr-app .pcr-swatches>button:not(.pcr-active) {
    box-shadow: none;
}

.pcr-app .pcr-interaction {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -0.2em 0 -0.2em;
}

.pcr-app .pcr-interaction>* {
    margin: 0 0.2em;
}

.pcr-app .pcr-interaction input {
    letter-spacing: 0.07em;
    font-size: 0.75em;
    text-align: center;
    cursor: pointer;
    color: #75797e;
    background: #f1f3f4;
    border-radius: 0.15em;
    transition: all 0.15s;
    padding: 0.45em 0.5em;
    margin-top: 0.75em;
}

.pcr-app .pcr-interaction input:hover {
    filter: brightness(0.975);
}

.pcr-app .pcr-interaction input:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(66, 133, 244, 0.75);
}

.pcr-app .pcr-interaction .pcr-result {
    color: #75797e;
    text-align: left;
    flex: 1 1 8em;
    min-width: 8em;
    transition: all 0.2s;
    border-radius: 0.15em;
    background: #f1f3f4;
    cursor: text;
}

.pcr-app .pcr-interaction .pcr-result::-moz-selection,
.pcr-app .pcr-interaction .pcr-result::selection {
    background: #4285f4;
    color: #ffffff;
}

.pcr-app .pcr-interaction .pcr-type.active {
    color: #ffffff;
    background: #4285f4;
}

.pcr-app .pcr-interaction .pcr-save,
.pcr-app .pcr-interaction .pcr-cancel,
.pcr-app .pcr-interaction .pcr-clear {
    color: #ffffff;
    width: auto;
}

.pcr-app .pcr-interaction .pcr-save,
.pcr-app .pcr-interaction .pcr-cancel,
.pcr-app .pcr-interaction .pcr-clear {
    color: #ffffff;
}

.pcr-app .pcr-interaction .pcr-save:hover,
.pcr-app .pcr-interaction .pcr-cancel:hover,
.pcr-app .pcr-interaction .pcr-clear:hover {
    filter: brightness(0.925);
}

.pcr-app .pcr-interaction .pcr-save {
    background: #4285f4;
}

.pcr-app .pcr-interaction .pcr-clear,
.pcr-app .pcr-interaction .pcr-cancel {
    background: #f44250;
}

.pcr-app .pcr-interaction .pcr-clear:focus,
.pcr-app .pcr-interaction .pcr-cancel:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(244, 66, 80, 0.75);
}

.pcr-app .pcr-selection .pcr-picker {
    position: absolute;
    height: 18px;
    width: 18px;
    border: 2px solid #ffffff;
    border-radius: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pcr-app .pcr-selection .pcr-color-palette,
.pcr-app .pcr-selection .pcr-color-chooser,
.pcr-app .pcr-selection .pcr-color-opacity {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    cursor: grab;
    cursor: -webkit-grab;
}

.pcr-app .pcr-selection .pcr-color-palette:active,
.pcr-app .pcr-selection .pcr-color-chooser:active,
.pcr-app .pcr-selection .pcr-color-opacity:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}

.pcr-app[data-theme=classic] {
    width: 28.5em;
    max-width: 95vw;
    padding: 0.8em;
}

.pcr-app[data-theme=classic] .pcr-selection {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
    position: relative;
    z-index: 1;
    width: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 0.75em;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 0.5em;
    border-radius: 0.15em;
    z-index: -1;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-last-color {
    cursor: pointer;
    border-radius: 0.15em 0.15em 0 0;
    z-index: 2;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-current-color {
    border-radius: 0 0 0.15em 0.15em;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-last-color,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-current-color {
    background: var(--pcr-color);
    width: 100%;
    height: 50%;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-palette {
    width: 100%;
    height: 8em;
    z-index: 1;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-palette .pcr-palette {
    flex-grow: 1;
    border-radius: 0.15em;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-palette .pcr-palette::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 0.5em;
    border-radius: 0.15em;
    z-index: -1;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
    margin-left: 0.75em;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser .pcr-picker,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity .pcr-picker {
    left: 50%;
    transform: translateX(-50%);
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser .pcr-slider,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity .pcr-slider {
    width: 8px;
    flex-grow: 1;
    border-radius: 50em;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser .pcr-slider {
    background: linear-gradient(to bottom, red, #ff0, lime, cyan, blue, #f0f, red);
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity .pcr-slider {
    background: linear-gradient(to bottom, transparent, black), url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 100%, 50%;
}

@supports (display: grid) {
    .pcr-app .pcr-swatches {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 1.75em);
    }
}
