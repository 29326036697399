.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Adjust as needed */
  visibility: hidden; /* Initially hidden */
}

.modal.show {
  visibility: visible; /* Show the modal when the 'show' class is applied */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 900; /* Lower than modal z-index but higher than content z-index */
}
.multiselect {
  width: 200px;
  position: relative;
  /* Ensure relative positioning for absolute positioning */
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.selectboxstyle {
  height: 4vh;
  display: inline;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #808080;
  transition: box-shadow 0.3s ease;
  outline: none;
  width: 180px;
  height: 40px;
  color: #808080;
  background: #fff;
  font-weight: 400 !important;
}

#checkboxes {
  position: absolute; /* Position dropdown absolutely */
  top: 100%; /* Position it just below the select box */
  left: 0;
  width: 100%; /* Match the width of the multiselect container */
  background-color: #fff;
  border: 1px solid #dadada;
  z-index: 999; /* Ensure it's above other content */
  padding-top: 0px;
  padding-bottom: 10px;
}

#checkboxes label {
  display: block;
  margin-bottom: 0;
  padding-left: 10px;
  color: #808080;
}

#checkboxes label:hover {
  background-color: #808080;
  color: black;
}
#checkboxes input {
  margin-right: 10px;
  height: 15px;
  width: 15px;
}
.downArrow {
  transform: translateY(-50%) rotate(90deg);
  position: "absolute";
  left: "180px";
  top: "10px";
  cursor: "pointer"; /* To ensure the arrow doesn't interfere with input */
  color: #888; /* Color of the arrow */
}
