.container {
    padding-top: 50px;
    margin: auto;
  }
  
  .field-icon {
    float: right;
    padding-right: 20px;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }
  
  /* Define other styles for your form components here */
  